@import '../../styles/core';


.expansionPanelTitle {
    width: 100%;
    height: 48px;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.14;
    color: #000000;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    padding: 0 11px 0 30px;
    background: #F7F6F5;
    transition: all 0.3s ease;

    &:after {
        content: ' ';
        width: 2px;
        height: 30px;
        background: #E0E0E0;
        border-radius: 2px;
        position: absolute;
        top: 9px;
        right: 46px;
    }

    & span {
        max-width: 86%;
        display: block;
    }

    @media (--phoneLandscape) {
        font-size: 14px;
        padding-left: 20px;
    }

    & .img {
        transform: rotate(180deg);
        transition: all .1s;
    }

    &.opened .img {
        transform: rotate(0deg);
    }
}

.expansionPanelContent {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 30px;
    height: 0;
    color: #080808;
    transition: all .1s;
    display: none;

    p {
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
    }

    @media (--phoneLandscape) {
        font-size: 12px;
        padding: 0 20px;

        p {
            font-size: 12px;
        }
    }

    &.opened {
        height: auto;
        padding: 16px 30px;
        transition: all .1s;
        display: block;

        p {
            margin: 10px 0 0;
        }

        p:first-child {
            margin: 0;
        }

        a[href^='tel:'],
        a[href^='tel:']:hover {
            white-space: nowrap;
            text-decoration: underline;
            font-weight: bold;
            color: #000000;
        }

        @media (--phoneLandscape) {
            font-size: 12px;
            padding: 16px 20px;
        }
    }
}
