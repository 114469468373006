/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable shorthand-property-no-redundant-values */
/* stylelint-disable order/order */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable color-hex-length */
/* stylelint-disable selector-pseudo-element-colon-notation */
/* stylelint-disable length-zero-no-unit */
/* stylelint-disable order/properties-order */
@import '../../../../styles/core';

.blogLayout {
  & .blogLayoutInner {
    max-width: 1320px;
    margin: 0 auto 70px;
    box-sizing: border-box;

    & .content {
      padding: 0;

      @media (--desktopLarge) {
        padding: 0 75px;
      }

      @media (--desktop) {
        padding: 0 48px;
      }

      @media (--desktopSmall) {
        padding: 0 40px;
      }

      @media (--tablet) {
        padding: 0 16px;
      }
    }
  }

  & .blogLayoutSubTitle {
    text-align: center;
    color: #68686e;
    font: 14px / 20px Manrope, sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin: 50px 0px 10px;
    width: 100%;

    & .blogLayoutSubTitleText {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      width: 200px;
      margin: 0 auto;
    }
  }

  & .blogLayoutTitle {
    text-align: center;
    margin: 0px 0px 70px;
    line-height: 42px;
    color: #212121;
    font-size: 32px;
    font-family: Manrope, system-ui, sans-serif;
    font-weight: 400;
  }

  & .blogLayoutNavigation {
    position: relative;
    margin-bottom: 40px;
    border-bottom: 1px solid #f0efee;

    & .blogLayoutNavigationList {
      white-space: nowrap;
      position: relative;
      overflow-x: auto;
      overflow-anchor: none;
      overflow-y: hidden;
      display: flex;
      list-style: none;

      & .blogLayoutNavigationListIdentificator {
        position: absolute;
        width: 200px;
        height: 2px;
        background: #191e80;
        bottom: 0;
        transition: all 0.3s;
      }

      & .blogLayoutNavigationListItem {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        transition: all 0.4s ease;

        & .blogLayoutNavigationListItemSelected {
          color: #191e80 !important;

          &:after {
            content: '';
            position: absolute;
            height: 2px;
            background: #191e80;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.3s;
          }
        }

        &:first-child {
          margin-left: auto;
        }

        &:last-child {
          margin-right: auto;
        }

        & a {
          display: block;
          white-space: nowrap;
          padding: 15px 15px 19px;
          color: #000;
          font-weight: bold;

          & small {
            font-size: 12px;
            position: relative;
            top: -5px;
          }
        }
      }
    }
  }

  & .blogLayoutSectionTitle {
    text-align: left;
    font-size: 16px;
    color: #000;
    margin-bottom: 25px;
    font-weight: 700;
  }

  & .blogLayoutFavorite {
    display: flex;

    @media screen and (max-width: 1020px) {
      flex-direction: column;
    }
  }

  & .blogLayoutFavoriteShadow {
    display: none;
  }

  & .blogLayoutFavoriteTitle {
    text-align: left;
    font-size: 16px;
    color: #000;
    margin-bottom: 25px;
    font-weight: 700;

    @media screen and (max-width: 1020px) {
      margin-left: 15px;
    }
  }

  & .blogLayoutFavoriteBigItem {
    width: 70%;
    height: 540px;
    background-size: cover;

    & .blogLayoutFavoriteInfoTitle {
      font-size: 39px;
      line-height: 48px;

      @media screen and (max-width: 1020px) {
        font-size: 18px;
        line-height: 24px;
        max-width: 95%;
      }
    }

    & .blogLayoutFavoriteSection {
      left: 30px;
      top: 45px;
      font-size: 12px;
      @media screen and (max-width: 1020px) {
        top: 20px;
        left: 10px;
      }
    }

    @media screen and (max-width: 1020px) {
      width: 100%;
      height: 200px;
    }
  }

  & .blogLayoutFavoriteSmall {
    height: 540px;
    width: 30%;
    padding: 0px 0px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .blogLayoutFavoriteSmallItem {
      width: 100%;
      height: 32%;
      background-size: cover;
    }

    @media screen and (max-width: 1020px) {
      padding: 0px 0px 0px 0px;
      width: 100%;
      & .blogLayoutFavoriteSmallItem {
        height: 200px;
      }
    }
  }

  & .blogLayoutFavoriteSection {
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    transition: all 0.3s;

    @media screen and (max-width: 1020px) {
      top: 20px;
      left: 10px;
    }
  }

  & .blogLayoutFavoriteSmallItem,
  & .blogLayoutFavoriteBigItem {
    &:hover {
      & .blogLayoutFavoriteShadow {
        display: block;
      }
    }
  }

  & .blogLayoutFavoriteLink {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
    cursor: pointer;
  }

  & .blogLayoutFavoriteInfo {
    position: absolute;
    bottom: 20px;
    left: 30px;
    @media screen and (max-width: 1020px) {
      left: 10px;
    }

    & .blogLayoutFavoriteInfoDate {
      margin-top: 10px;
      font-size: 16px;
      color: #ffffff;
    }

    & .blogLayoutFavoriteInfoTitle {
      cursor: pointer;
      display: block;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #ffffff;
      max-width: 70%;
      transition: all 0.3s;

      &:hover {
        color: #191e80;
      }

      @media screen and (max-width: 1020px) {
        font-size: 18px;
        line-height: 24px;
        max-width: 95%;
      }
    }

    & .blogLayoutFavoriteInfoReadMore {
      display: inline-block;
      margin-top: 20px;
      background: #000;
      color: #ffffff;
      font: 14px / 20px Manrope, sans-serif;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      font-size: 12px;
      padding: 11px 15px;
      transition: all 0.3s;

      & img {
        transform: rotate(-90deg);
        height: 5px;
      }

      &:hover {
        background: #191e80;
      }

      @media screen and (max-width: 1020px) {
        display: none;
      }
    }
  }

  & .blogLayoutLastAndPopular {
    display: flex;
    margin: 60px 0px 0px 0px;
    @media screen and (max-width: 1020px) {
      flex-direction: column;
    }
  }

  & .blogLayoutLast {
    width: 70%;
    @media screen and (max-width: 1020px) {
      width: 100%;
      padding: 0px 15px;
      box-sizing: border-box;
    }

    & .blogLayoutLastTitle {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      padding-bottom: 12px;
      text-align: left;
      font-size: 16px;
      color: #000;
      margin-bottom: 25px;
      font-weight: 700;
    }

    & .blogLayoutLastShowMoreButton {
      width: 256px;
      height: 64px;
      background-color: #f7f6f5;
      color: #191e80;
      font: 600 16px / 64px Manrope, sans-serif;
      cursor: pointer;
      text-align: center;
      transition: all 0.3s;
      margin: 50px auto;

      &:hover {
        background: #ebe9e8;
      }
    }
  }
}
