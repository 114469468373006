@import '../../../../../../styles/core';


.drawer {
  position: fixed;
  top: 41px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 41px);
  overflow-y: auto;
  z-index: 1000;
  display: none;
  background-color: transparent;

  @media (--tablet) {
    background-color: var(--colorWhite);
  }

  &.opened {
    @media (--desktopSmall) {
      display: block;
    }
  }

  & .drawerBackdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.24);
    z-index: 1;
  }

  & .drawerContent {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
  }

  & .drawerExpansionContent {
    width: calc(100% - 375px);
    min-height: 100%;
    background-color: var(--colorBackgroundPrimary);
    z-index: 10;

    @media (--tablet) {
      width: 100%;
    }
  }

  & .drawerSections {
    width: 375px;
    min-height: 100%;
    z-index: 10;
    padding: 0 15px 164px 15px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--colorWhite);
    border-right: 1px solid var(--colorBorderDefault);
    box-sizing: border-box;

    @media (--tablet) {
      border-right: none;
      width: 100%;
    }

    &.withPaddedBottomTablet {
      @media (--tablet) {
        padding-bottom: 224px;
      }
    }
  }

  & .drawerFeaturesSection {
    width: 100%;
  }

  & .drawerShopsSection {
    width: calc(100% + 30px);
    padding: 0 15px;
    margin: 6px -15px 0 -15px;
    box-sizing: border-box;

    & .drawerShopsSectionRow {
      display: flex;
      padding: 4px 0;
      justify-content: space-between;

      & + .drawerSectionRow {
        border-top: 1px solid var(--colorBorderDefault);
      }
    }

    & .drawerShopsLink {
      display: flex;
      align-items: center;
      height: 34px;
      margin: 0 0 0 8px;
      text-decoration: underline;
      white-space: nowrap;
      text-decoration: none;

      & .drawerShopsLinkIcon {
        margin: 0 8px 0 0;

        & svg {
          display: block;
          fill: var(--colorPrimary);
        }
      }

      & .drawerSectionLinkIcon {
        &.drawerSectionLinkArrowRightIcon {
          & svg {
            display: block;
            fill: #B7B9BD;
          }
        }
      }
    }

    & .drawerShopsRegion {
      width: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 34px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;

      & .drawerShopsLinkTitle {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
      }

      & svg {
        margin: 0;
      }

      & .drawerShopsLinkIcon {
        margin: 0 10px 0 0;

        & svg {
          display: block;
        }
      }

      & .drawerSectionLinkIcon {
        &.drawerSectionLinkArrowRightIcon {
          & svg {
            display: block;
            fill: #B7B9BD;
          }
        }
      }
    }

    & .drawerShopsPhone {
      display: flex;
      align-items: center;
      height: 34px;
      text-decoration: underline;

      & svg {
        margin: 0 10px 0 0;
        fill: var(--colorIconAccent);
      }
    }
  }

  & .drawerSocialSection {
    margin: 16px 0 64px 0;
    width: 100%;

    & .drawerSocialLinks {
      margin: 15px 0 0 0;
      display: flex;

      & .drawerSocialLink {
        width: 40px;
        height: 40px;
        background: #D9DADE;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        & + .drawerSocialLink {
          margin-left: 16px;
        }
      }

      & .drawerSocialLinkIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          fill: var(--colorPrimary);
        }

        &:hover {
          & svg {
            fill: var(--colorHover);
          }
        }

        &.ig {
          height: 30px;
          width: 29px;
        }

        &.fb {
          height: 30px;
          width: 30px;
        }

        &.vk {
          height: 16px;
          width: 29px;
        }

        &.ok {
          height: 25px;
          width: 26px;
        }
      }
    }
  }

  & .drawerSection {
    width: 100%;
    position: static;

    &.paddedMini {
      padding: 10px 0;
    }

    &.padded {
      padding: 16px 0;
    }

    &.fixed {
      position: fixed;
      bottom: 16px;
      left: 15px;
      right: 15px;
    }

    &.parametersButtonSection {
      padding: 16px 0;
      width: auto;

      @media (--tablet) {
        position: fixed;
        bottom: 52px;
        left: 15px;
        right: 15px;
      }
    }

    &.withBorderTop {
      border-top: 1px solid var(--colorBorderDefault);
    }
  }

  & .drawerSectionLink {
    width: 100%;
    height: 48px;
    background: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    outline: none;
    border: none;
    text-decoration: none;
    box-sizing: border-box;

    &.mini {
      height: 38px;
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.blackFriday {
      background-color: black;
      color: var(--colorWhite);
      margin: 0 -15px;
      width: calc(100% + 30px);
      padding: 0 15px;
    }

    &.newYear {
      margin: 0 -15px;
      width: calc(100% + 30px);
      padding: 0 15px;
    }

    &.accent {
      margin: 0 -16px;
      padding: 0 16px;
      width: calc(100% + 32px);
      background-color: rgba(213, 196, 167, 0.5);

      & + &:not(.accent) {
        border-bottom: none;
      }
    }

    & .drawerSectionLinkContent {
      display: flex;
      align-items: flex-end;
    }

    & .drawerSectionLinkIcon {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      & svg {
        display: block;
        fill: #B7B9BD;
      }
    }

    & .drawerSectionLinkLoginIcon {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }

    & .drawerSectionLinkArrowRightIcon {
      margin-left: 5px;
      width: 6px;
      height: 10px;
    }

    & .drawerSectionLinkRightIcon {
      margin-left: 16px;
      width: 8px;
      height: 14px;
    }

    & .drawerSectionLinkLeftIcon {
      margin-right: 16px;
      width: 20px;
      height: 20px;
    }
  }

  & .drawerSectionBottomBorder {
    border-bottom: 1px solid var(--colorBorderDefault);
  }

  & .drawerSectionDropdown {
    background-color: transparent;
    padding: 0 15px;
  }
}

.articles {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 0 50px 0;
  margin: -10px -10px 0 -10px;
  width: calc(100% + 20px);

  & .article {
    width: calc(100% - 20px);
    height: 100%;
    background-color: var(--colorWhite);
    margin: 10px;
    border-bottom: 1px solid var(--colorBorderDefault);

    & .articleImg {
      object-fit: cover;
      width: 100%;
      height: 190px;
    }

    & .articleHeader {
      padding: 15px 0;
    }
  }
}

.styles {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: -8px 0;

  & .style {
    width: auto;
    height: 40px;
    border-radius: 2px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    margin: 8px 0;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    color: #FFFFFF;
    border: 0.4px solid #000000;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.count {
  text-transform: lowercase;
}

.catalogButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 24px;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background-color: var(--newColorTextAccent);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--newColorTextHover);
  }

  .catalogButtonIcon {
    width: 16px;
    height: 16px;
  }
}

.parametersButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 24px;
  width: auto;
  height: 40px;
  border: none;
  outline: none;
  background-color: var(--newColorTextAccent);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--newColorTextHover);
  }

  .parametersButtonIcon {
    width: 16px;
    height: 16px;
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #B7B9BD;

  .tab {
    width: 50%;
    height: 52px;
    margin-bottom: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: none;
    border: none;
    outline: none;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;

    &.selected {
      border-bottom: 4px solid var(--newColorTextAccent);
    }
  }
}


.banners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -4px;
  width: calc(100% + 8px);
  padding: 12px 0 4px 0;

  & .banner {
    margin: 4px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: calc(50% - 8px);
    height: auto;

    @media (--tablet) {
      width: calc(50vw - 15px - 12px);
      height: calc((50vw - 15px - 12px) * 9 / 16);
    }

    &:after {
      content: '';
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .2);
    }

    &:hover {
      &:after {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    & .bannerImg {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .bannerHeader {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        display: block;
        padding: 8px 16px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 30px;
        font-size: 14px;
        line-height: 20px;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
      }
    }
  }
}

.links {
  flex-shrink: 0;
  margin: 8px 0 0 0;
  padding: 14px 16px 20px 16px;
  border-radius: 8px;
  background-color: #F3F3F4;

  .linksHeader {
    font-size: 14px;
    line-height: 20px;
  }

  .linksItems {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 12px -2px -2px -2px;
    width: calc(100% + 4px);
    box-sizing: border-box;
  }

  .linksItem {
    height: 32px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    cursor: pointer;
    padding: 4px 12px;
    background-color: white;
    border-radius: 16px;
    box-sizing: border-box;
    text-decoration: none;

    & svg {
      margin-top: -2px;
    }
  }

  .linksItemImg {
  }

  .linksItemHeader {
  }
}

.colors {
  flex-shrink: 0;
  margin: 8px 0 0 0;
  padding: 14px 16px 20px 16px;
  border-radius: 8px;
  background-color: #F3F3F4;

  .colorsHeader {
    font-size: 14px;
    line-height: 20px;
  }

  .colorsItems {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 12px -4px -4px -4px;
    width: calc(100% + 8px);
  }

  .colorsItem {
    padding: 0;
    background-repeat: no-repeat;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 4px;
    width: 38px;
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.27);
    border-radius: 50%;
    cursor: pointer;
    background-size: 200% 200%;
    background-position: center;
    position: relative;

    &:after {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 3px solid var(--colorWhite);
      border-radius: 50%;
      content: '';
      display: none;
      box-sizing: border-box;
    }

    &.selected {
      border-width: 3px;
      border-color: var(--colorHover);

      &:after {
        display: block;
      }
    }
  }

  .colorsDescription {
    font-size: 14px;
    line-height: 20px;
    color: var(--newColorTextSecondary);
    margin: 12px 0 0 0;
    text-align: left;
  }
}

.singleLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 8px 0 0 0!important;
  padding: 15px 16px 15px 16px!important;
  border-radius: 8px;
  background-color: #F3F3F4;
  text-decoration: none;
}
