@import '../../styles/core';


.textField {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #D9DADE;
  font-family: Manrope, sans-serif;

  &.textFieldError {
    border-bottom-color: #D0021B;
  }

  & .textFieldInput {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 20px 0;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    border: none;
    outline: none;

    color: var(--colorTextPrimary);

    @media (--desktop) {
      padding: 15px 20px 15px 0;
    }

    @media (--tablet) {
      padding: 9px 20px 9px 0;
    }

    &[type='datetime-local'] {
      height: 36px;
      font-family: Manrope, system-ui, sans-serif;
    }

    &::placeholder {
      color: var(--colorTextSecondary);
      font-family: Manrope, sans-serif;
    }

    &::-webkit-date-and-time-value {
      text-align: left;
    }

    @supports (-webkit-touch-callout: none) {
      &[type='datetime-local']:before {
        content: 'Дата звонка';
        color: var(--colorTextSecondary);
        text-align: left;
        width: 100%;
        font-family: Manrope, system-ui, sans-serif;
      }

      &[type='datetime-local']:not([value='']):before,
      &[type='datetime-local']:active:before,
      &[type='datetime-local']:hover:before,
      &[type='datetime-local']:visited:before,
      &[type='datetime-local']:focus:before {
        content: '';
        display: none;
      }
    }
  }

  & .textFieldError {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #D0021B;
  }

  &.disabled {
    border-color: var(--colorBorderSecondary);

    & .textFieldInput {
      color: var(--colorTextSecondary);
    }
  }

  &.mini {
    & .textFieldError {
      top: calc(100% + 2px);
    }
  }

  &.white {
    border-bottom: none;

    & .textFieldInput {
      padding: 14px 25px;
    }

    &.textFieldError {
      border-bottom: none;
    }

  }

  &.gray {
    border-bottom: none;

    & .textFieldInput {
      background-color: #f4f4f4;
      padding: 14px 16px;
    }

    &.textFieldError {
      border-bottom: none;
    }

  }

  &.bordered {
    border: 1px solid var(--colorBorderDefault);
    border-radius: 4px;

    & .textFieldInput {
      background-color: transparent;
      padding: 8px 8px;
    }

    &.textFieldError {
      top: calc(100% + 2px);
    }

  }
}

.clear {
  position: absolute;
  top: calc(50% - 14px);
  right: 8px;
  outline: none;
  background-color: transparent;
  border: none;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #C4C4C4;
  font-size: 14px;
  cursor: pointer;
}
