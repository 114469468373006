/* stylelint-disable no-descending-specificity */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable length-zero-no-unit */
.layout {
  box-sizing: border-box;
  width: 30%;
  padding: 0px 0px 0px 16px;

  @media screen and (max-width: 1020px) {
    width: 100%;
    padding: 0px 15px;
  }

  & .popularTitle {
    margin-bottom: 25px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
  }

  & .popularList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -10px 0;

    @media screen and (max-width: 1020px) {
      flex-direction: row;
      margin: 0;
    }
  }

  & .popularItem {
    display: flex;
    overflow: hidden;
    width: 100%;
    margin: 10px 0;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      width: 100%;
    }

    @media screen and (max-width: 1020px) {
      flex-direction: column;
      width: calc(50% - 20px);
    }
    @media screen and (max-width: 650px) {
      width: 100%;
    }

    &:hover {
      & .popularItemImageShadow {
        display: block;
      }

      & .popularItemContent {
        box-shadow: 0 7px 10px 0 rgba(163, 163, 163, 0.21), 0 1px 1px 0 rgba(209, 209, 209, 0.3);
      }

      & .popularItemContentText {
        color: #191e80;
      }
    }

    & .popularItemImage {
      position: relative;
      flex-shrink: 0;
      width: 96px;
      height: auto;
      border: 1px 0px 1px 0px solid rgba(0, 0, 0, 0.06);
      border: 1px transparent;
      border-radius: 2px 0 0 2px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (max-width: 1280px) {
        width: 100%;
        height: 240px;
      }

      & .popularItemImageHoverBlock {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        cursor: pointer;
        transition: all 0.3s;
      }

      & .popularItemImageShadow {
        display: none;
      }
    }

    & .popularItemContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% - 96px);
      padding: 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);

      @media screen and (max-width: 1020px) {
        width: 100%;
      }

      & .popularItemContentDate {
        margin-top: 10px;
        color: #68686e;
        font-size: 12px;
      }

      & .popularItemContentButton {
        overflow: hidden;
        max-width: 100%;
        margin-bottom: 10px;
        padding: 2px 8px;
        border: 1px solid rgba(135, 135, 143, 0.4);
        color: #87878f;
        box-shadow: 0px 0px 0px 0px;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 0.3s;
      }

      & .popularItemContentText {
        display: block;
        color: #49494d;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
