@import '../../../../../../styles/core';

.blogDropdown {
  background: var(--colorWhite);

  & .blogDropdownContent {
    display: flex;

    @media (--desktopSmall) {
      flex-direction: column;
    }
  }

  & .blogDropdownDelimeter {
    height: 1px;
    width: 100%;
    margin: 12px 0;
    background-color: var(--colorBorderDefault);

    @media (--tablet) {
      margin: 10px 0;
    }

    &.tabletDelimeter {
      margin: 0 16px;

      @media (--tablet) {
        margin: 0;
      }
    }
  }

  & .blogDropdownHint {
    padding: 12px 0;
  }

  & .blogDropdownLink {
    padding: 12px 0;
    white-space: nowrap;
    text-decoration: none;

    &:hover > * {
      color: var(--colorHover);
    }

    @media (--tablet) {
      padding: 10px 0;
    }

    &.main {
      align-items: flex-start;

      & strong {
        text-transform: uppercase;
      }

      & span {
        font-weight: 400;
      }

      @media (--desktopSmall) {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        & > * + * {
          margin-left: 5px;
        }
      }
    }
  }

  & .blogDropdownRow {
    display: flex;
    height: 100%;

    & + .blogDropdownRow {
      border-top: 1px solid var(--colorBorderDefault);
    }

    @media (--desktopSmall) {
      flex-wrap: wrap;
    }
  }

  & .blogDropdownSecondaryRow {
    display: flex;
    padding: 0;
    margin: 0 25px;

    &:first-of-type {
      padding: 15px 0;
      align-items: baseline;
    }

    & + .blogDropdownSecondaryRow {
      border-top: 1px solid var(--colorBorderDefault);
    }

    & .blogDropdownLink {
      & + .blogDropdownLink {
        margin-left: 10px;

        @media (--desktopSmall) {
          margin-left: 0;
        }
      }
    }

    @media (--desktopSmall) {
      border-top: 1px solid var(--colorBorderDefault);
      flex-direction: column;
      margin: 0 16px;

      &:first-of-type {
        align-items: flex-start;
      }
    }

    @media (--tablet) {
      margin: 0;
    }
  }

  & .blogDropdownColumnMain {
    background-color: var(--colorBackgroundPrimary);
    padding: 18px 16px 18px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (--desktopSmall) {
      width: 100%;
      background-color: transparent;
    }

    @media (--tablet) {
      padding: 10px 0;
    }

    & + .blogDropdownColumnMain {
      border-left: 1px solid var(--colorBorderDefault);

      @media (--desktopSmall) {
        border-left: none;
      }
    }

    & .blogDropdownColumnMainCategories {
      display: flex;
      flex-direction: column;
    }
  }

  & .blogDropdownColumnSecondary {
    padding: 18px 16px;
    display: flex;
    flex-direction: column;

    @media (--tablet) {
      width: 100%;
      padding: 10px 0;
    }
  }

  & .blogDropdownColumn {
    display: flex;
    flex-direction: column;
    padding: 0;

    &:first-child {
      display: flex;

      @media (--desktopSmall) {
        width: 100%;
      }
    }

    &:last-child {
      width: 100%;
      display: flex;
    }
  }

  & .blogDropdownFooter {
    width: 100%;
    padding: 0 16px 30px 16px;

    @media (--tablet) {
      width: 100%;
      padding: 0 0 30px 0;
    }
  }


  & .blogDropdownColors {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    & .blogDropdownColor {
      flex-shrink: 0;
      flex-grow: 0;
      margin: 10px;
      width: 25px;
      height: 25px;
      border: 1px solid rgba(0, 0, 0, 0.27);
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease;
      background-size: 200% 200%;
      background-position: center;

      &:hover {
        border-color: var(--colorHover);
      }
    }
  }

  & .blogDropdownBlog {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0 50px 0;
    margin: -10px -10px 0 -10px;
    width: calc(100% + 20px);

    & .blogDropdownBlogItem {
      width: calc(33.3% - 20px);
      height: 100%;
      background-color: var(--colorWhite);
      margin: 10px;
      border: 1px solid var(--colorBorderDefault);
      box-sizing: border-box;
      text-decoration: none;

      @media (--desktopSmall) {
        width: calc(50% - 20px);
      }

      @media (--tabletLandscape) {
        width: calc(100% - 20px);
      }

      & .blogDropdownBlogItemImg {
        object-fit: cover;
        width: 100%;
        height: 140px;
      }

      & .blogDropdownBlogItemHeader {
        padding: 15px;
      }
    }
  }

  & .desktopLarge {
    @media (--desktopSmall) {
      display: none;
    }
    @media (--tablet) {
      display: none;
    }
  }

  & .notDesktopLargeFlex {
    display: none;

    @media (--desktopSmall) {
      display: flex;
    }
  }
}
