
 .articleList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & .articleListItem {
    margin-top: 20px;
    width: calc(50% - 10px);
    background: #F7F6F5;
    box-shadow: 0 4px 5px 0 transparent;
    transition: all 0.3s;
    @media screen and (max-width: 650px) {
      width: 100%;
    }

    &:hover {
      box-shadow: 0 4px 5px 0 rgba(64, 64, 64, 0.1), 0 1px 1px 0 rgba(209, 209, 209, 0.3);

      & .articleListItemShadow {
        display: block;
      }
    }

    & .articleListItemShadow {
      display: none;
    }

    & .articleListItemImage {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      height: 240px;

      & .articleListItemImageButton {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        padding: 6px 10px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        transition: all 0.3s;
      }
    }

    & .articleListItemContent {
      padding: 25px 25px 35px;

      & .articleListItemContentText {
        display: block;
        font-size: 19px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 10px;
        color: #49494D;
      }

      & .articleListItemContentDate {
        font-size: 12px;
        color: #68686e;
      }
    }
  }
}
